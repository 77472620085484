import React, { useEffect } from 'react';

const HubSpotAuth = () => {
  useEffect(() => {
    // Redirect user to HubSpot OAuth2 authorization page
    const client_id = '5dcec149-d241-4fff-8fc2-ae6004225aa4';
    const redirect_uri = 'http://localhost:3000/callback'; // Your redirect URI
    // const scopes = 'contacts crm.objects.contacts.read'; // Define the necessary scopes
    const scopes = 'crm.objects.contacts.write&crm.schemas.contacts.write&crm.dealsplits.read_write&oauth&crm.schemas.contacts.read&crm.objects.contacts.read'; // Define the necessary scopes

    const authUrl = `https://app.hubspot.com/oauth/authorize?client_id=${client_id}&scope=${scopes}&redirect_uri=${redirect_uri}`;
    window.location.href = authUrl; // This redirects the user to HubSpot for authorization
  }, []);

  return (
    <div>
      <h2>Redirecting to HubSpot...</h2>
    </div>
  );
};

export default HubSpotAuth;
