// import React, { useState } from 'react';
// import './css/login.css';

// const Login = () => {
//   const [username, setUsername] = useState('');
//   const [password, setPassword] = useState('');
//   const [error, setError] = useState('');

//   const handleUsernameChange = (e) => {
//     setUsername(e.target.value);
//   };

//   const handlePasswordChange = (e) => {
//     setPassword(e.target.value);
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     if (username === '' || password === '') {
//       setError('Please fill out both fields');
//     } else {
//       setError('');
//       alert(`Logged in with username: ${username}`);
//     }
//   };

//   return (
//     <div className="login-container">
//       <h2>Login</h2>
//       <form onSubmit={handleSubmit}>
//         <div className="form-group">
//           <label htmlFor="username">Username</label>
//           <input
//             type="text"
//             id="username"
//             value={username}
//             onChange={handleUsernameChange}
//             required
//           />
//         </div>

//         <div className="form-group">
//           <label htmlFor="password">Password</label>
//           <input
//             type="password"
//             id="password"
//             value={password}
//             onChange={handlePasswordChange}
//             required
//           />
//         </div>

//         {error && <p className="error-message">{error}</p>}

//         <button type="submit" className="login-btn">Login</button>
//       </form>
//     </div>
//   );
// };

// export default Login;



import React, { useState } from 'react';
import './css/login.css';

const Login = (props) => {
  const [email, setEmail] = useState(''); // Track email input
  const [password, setPassword] = useState(''); // Track password input
  const [error, setError] = useState(null); // Track login error

  const redirectHubSpotClick = () => {
        const client_id = '5dcec149-d241-4fff-8fc2-ae6004225aa4';
        const redirect_uri = 'https://getfollowups.tktechnico.com/callback';
        const scopes = 'crm.objects.contacts.write crm.schemas.contacts.write oauth crm.schemas.contacts.read crm.objects.contacts.read';
        const authUrl = `https://app.hubspot.com/oauth/authorize?client_id=${encodeURIComponent(client_id)}&scope=${encodeURIComponent(scopes)}&redirect_uri=${encodeURIComponent(redirect_uri)}`;
        window.location.href = authUrl; // This redirects the user to HubSpot for authorization
    };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = () => {
    // Hardcoded email and password for demo purposes
    const validEmail = 'test@test.com';
    const validPassword = 'test@12345';

    if (email === validEmail && password === validPassword) {
      setError(null); 

      let ACInformation = {
        email: email,
        password: password,
        valid: true
      }
      localStorage.setItem('getFollowUpsInfo', JSON.stringify(ACInformation));
      redirectHubSpotClick();
    } else {
      setError('Invalid email or password');

      let ACInformation = {
        email: email,
        password: password,
        valid: false
      }
      localStorage.setItem('getFollowUpsInfo', JSON.stringify(ACInformation));

      
    }
  };

  return (
    <div className="login-container">
      <h1>GetFollowUps.com</h1>
      <h2>Login</h2>
      <div className="form-group">
        <label htmlFor="username">Email Id</label>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={handleEmailChange}
        />
      </div>
      <div className="form-group">
        <label htmlFor="password">Password</label>
        <input
          type="password"
          id="password"
          placeholder="Password"
          value={password}
          onChange={handlePasswordChange}
          required
        />
      </div>
      <button onClick={handleLogin}>Log In</button>
      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

export default Login;