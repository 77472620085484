import React from 'react';
import './css/home.css';
import { useNavigate } from "react-router-dom";

const Home = () => {
    const navigate = useNavigate();
    // const handleConnectClick = () => {
    //     const client_id = '5dcec149-d241-4fff-8fc2-ae6004225aa4';
    //     const redirect_uri = 'https://getfollowups.tktechnico.com/callback';
    //     const scopes = 'crm.objects.contacts.write crm.schemas.contacts.write crm.dealsplits.read_write oauth crm.schemas.contacts.read crm.objects.contacts.read';
    //     const authUrl = `https://app.hubspot.com/oauth/authorize?client_id=${encodeURIComponent(client_id)}&scope=${encodeURIComponent(scopes)}&redirect_uri=${encodeURIComponent(redirect_uri)}`;
    //     window.location.href = authUrl; // This redirects the user to HubSpot for authorization
    // };

    const handleConnectClick = () =>{
        navigate("/login");
    }


    return (
        <div className="home-page">
            <header className="header">
                <h1>GetFollowUps</h1>
            </header>
            <main className="main-content">
                <button onClick={handleConnectClick} className="connect-button">
                    Connect
                </button>
            </main>
        </div>
    );
};

export default Home;
