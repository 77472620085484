import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Contacts = ({ accessToken }) => {
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const response = await axios.get('http://localhost:5000/get-contacts', {
          params: { access_token: accessToken },
        });
        setContacts(response.data.results);
      } catch (error) {
        console.error('Error fetching contacts', error);
      }
    };

    if (accessToken) {
      fetchContacts();
    }
  }, [accessToken]);

  return (
    <div>
      <h2>Contacts</h2>
      <ul>
        {contacts.map((contact) => (
          <li key={contact.id}>{contact.properties.firstname} {contact.properties.lastname}</li>
        ))}
      </ul>
    </div>
  );
};

export default Contacts;




// import React from 'react';

// const Contacts = () => {
//   const data = {
//     results: [
//       {
//         objectId: 245,
//         title: "API-22: APIs working too fast",
//         link: "http://example.com/1",
//         created: "2016-09-15",
//         priority: "HIGH",
//         project: "API",
//         description: "Customer reported that the APIs are just running too fast. This is causing a problem in that they're so happy.",
//         reporter_type: "Account Manager",
//         status: "In Progress",
//         ticket_type: "Bug",
//         updated: "2016-09-28",
//         actions: [
//           {
//             type: "IFRAME",
//             width: 890,
//             height: 748,
//             uri: "https://example.com/edit-iframe-contents",
//             label: "Edit",
//             associatedObjectProperties: []
//           },
//           {
//             type: "IFRAME",
//             width: 890,
//             height: 748,
//             uri: "https://example.com/reassign-iframe-contents",
//             label: "Reassign",
//             associatedObjectProperties: []
//           },
//           {
//             type: "ACTION_HOOK",
//             httpMethod: "PUT",
//             associatedObjectProperties: [],
//             uri: "https://example.com/tickets/245/resolve",
//             label: "Resolve"
//           },
//           {
//             type: "CONFIRMATION_ACTION_HOOK",
//             confirmationMessage: "Are you sure you want to delete this ticket?",
//             confirmButtonText: "Yes",
//             cancelButtonText: "No",
//             httpMethod: "DELETE",
//             associatedObjectProperties: ["protected_account"],
//             uri: "https://example.com/tickets/245",
//             label: "Delete"
//           }
//         ]
//       },
//       {
//         objectId: 988,
//         title: "API-54: Question about bulk APIs",
//         link: "http://example.com/2",
//         created: "2016-08-04",
//         priority: "HIGH",
//         project: "API",
//         reported_by: "ksmith@hubspot.com",
//         description: "Customer is not able to find documentation about our bulk Contacts APIs.",
//         reporter_type: "Support Rep",
//         status: "Resolved",
//         ticket_type: "Bug",
//         updated: "2016-09-23",
//         properties: [
//           {
//             label: "Resolved by",
//             dataType: "EMAIL",
//             value: "ijones@hubspot.com"
//           },
//           {
//             label: "Resolution type",
//             dataType: "STRING",
//             value: "Referred to documentation"
//           },
//           {
//             label: "Resolution impact",
//             dataType: "CURRENCY",
//             value: "94.34",
//             currencyCode: "GBP"
//           }
//         ],
//         actions: [
//           {
//             type: "IFRAME",
//             width: 890,
//             height: 748,
//             uri: "https://example.com/edit-iframe-contents",
//             label: "Edit"
//           },
//           {
//             type: "CONFIRMATION_ACTION_HOOK",
//             confirmationMessage: "Are you sure you want to delete this ticket?",
//             confirmButtonText: "Yes",
//             cancelButtonText: "No",
//             httpMethod: "DELETE",
//             associatedObjectProperties: ["protected_account"],
//             uri: "https://example.com/tickets/245",
//             label: "Delete"
//           }
//         ]
//       }
//     ],
//     settingsAction: {
//       type: "IFRAME",
//       width: 890,
//       height: 748,
//       uri: "https://example.com/settings-iframe-contents",
//       label: "Settings"
//     },
//     primaryAction: {
//       type: "IFRAME",
//       width: 890,
//       height: 748,
//       uri: "https://example.com/create-iframe-contents",
//       label: "Create Ticket"
//     }
//   };

//   return (
//     <div>
//       <h1>Tickets</h1>
//       {data.results.map(ticket => (
//         <div key={ticket.objectId}>
//           <h2>{ticket.title}</h2>
//           <p><strong>Priority:</strong> {ticket.priority}</p>
//           <p><strong>Status:</strong> {ticket.status}</p>
//           <p><strong>Description:</strong> {ticket.description}</p>
//           <div>
//             <h3>Actions:</h3>
//             {ticket.actions.map((action, index) => (
//               <button
//                 key={index}
//                 onClick={() => window.open(action.uri, '_blank')}
//                 style={{ margin: '5px' }}
//               >
//                 {action.label}
//               </button>
//             ))}
//           </div>
//         </div>
//       ))}
//       <button
//         onClick={() => window.open(data.primaryAction.uri, "_blank")}
//       >
//         {data.primaryAction.label}
//       </button>
//       <button
//         onClick={() => window.open(data.settingsAction.uri, "_blank")}
//       >
//         {data.settingsAction.label}
//       </button>
//     </div>
//   );
// };

// export default Contacts;
