import './App.css';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HubSpotAuth from './components/HubSpotAuth';
import Callback from './components/Callback';
import Contacts from './components/Contacts';
import ContactDetails from './page/ContactDetails';
import Login from './page/Login';
import Home from './page/Home';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/hubspot-auth" element={<HubSpotAuth />} />
        <Route path="/callback" element={<Callback />} />
        <Route path="/" element={<Home />} />
        <Route path="/contacts" element={<Contacts />} />
        <Route path="/contact-details" element={<ContactDetails />} />
        <Route path="/login" element={<Login />} />
      </Routes>
    </Router>
  );
}

export default App;
