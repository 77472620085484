// import React, { useEffect, useState } from 'react';
// import axios from 'axios';

// const Callback = () => {
//     const [token, setToken] = useState(null);

//     useEffect(() => {
//         const params = new URLSearchParams(window.location.search);
//         const code = params.get('code'); // Get the authorization code from URL

//         alert(code);
//         if (code) {
//             // Step 1: Send the code to the backend to exchange for an access token
//             const exchangeCodeForToken = async () => {
//                 try {
//                     const response = await axios.post('http://localhost:5000/exchange-code', {
//                         code, // Send the code to the backend
//                     });
//                     setToken(response.data.access_token); // Store the token
//                 } catch (error) {
//                     console.error('Error exchanging code for access token:', error);  // Log the error in React
//                 }
//             };

//             exchangeCodeForToken();
//         }
//     }, []);


//     return (
//         <div>
//             <h2>Callback Page</h2>
//             {token ? (
//                 <div>
//                     <p>Access Token: {token}</p>
//                 </div>
//             ) : (
//                 <p>Exchanging code for access token...</p>
//             )}
//         </div>
//     );
// };

// export default Callback;




import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Login from '../page/Login';
// import Login from './Login';

const Callback = () => {
    const [token, setToken] = useState(null);
    // const [isLoggedIn, setIsLoggedIn] = useState(false); // Track login status
    let storedACInfo = JSON.parse(localStorage.getItem('getFollowUpsInfo'));

    // Check if the ACInformation exists and is valid
    let isLoggedIn = storedACInfo && storedACInfo.valid;

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const code = params.get('code'); // Get the authorization code from URL

        if (isLoggedIn && code) {
            exchangeCodeForToken(code);
        }
    }, [isLoggedIn]);

    const exchangeCodeForToken = async (code) => {
        storedACInfo =JSON.stringify(storedACInfo); 
        try {
            const response = await axios.post('https://landerr-gettestapp.azurewebsites.net/api/HubSpot/exchange-code', {
                code, storedACInfo
            });
            console.log('exchangeCodeForToken ----> ', response)
            setToken(response.data.access_token);
        } catch (error) {
            console.error('Error exchanging code for access token:', error);
        }
    };
    

    return (
        <div>
            <h2>Callback Page</h2>
            {isLoggedIn ? (
                token ? (
                    <div>
                        <p>Access Token: {token}</p>
                    </div>
                ) : (
                    <p>Exchanging code for access token...</p>
                )
            ) : (
                <h1>Please Login</h1>
            )}
        </div>
    );
};

export default Callback;
