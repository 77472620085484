import React from 'react';
import './css/contactDetails.css';

function ContactDetails() {
    return (
        <div className="bodyStyle">
            <div className="containerStyle">
                <h1>getfollowups.tktechnico.com</h1>
                <h3>Example Domain - getfollowups.tktechnico.com</h3>
                <p>This test page</p>
                <h2>Hello</h2>
            </div>
        </div>
    );
}

export default ContactDetails;
